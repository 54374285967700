import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, Dropdown, Spinner, Card, Collapse } from 'react-bootstrap';
import { SERVERURL } from '../config/VARS';
import ManagementSelector from './ManagementSelection';
import infludataLogo from './infludata-logo.png';
import './Hello.css';
import customFetch from './customFetch';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons for arrow

async function getUsernameSuggestions(letters, platform) {
  if (letters.length < 3) {
    return [];
  }
  let response = await customFetch(
    `${SERVERURL}getUserSuggestionByLetters?letters=${letters}&index=${platform}`,
    {
      headers: {
        accept: 'application/json, text/plain, */*',
      },
      method: 'GET',
      credentials: 'include',
    }
  );
  let data = await response.json();
  console.log(data);
  return data;
}

function Hello() {
  const [username, setUsername] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [platform, setPlatform] = useState('instagram');
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState([]);
  const [open, setOpen] = useState(false); // State to control collapse

  useEffect(() => {
    async function fetchCreators() {
      try {
        let response = await customFetch(`${SERVERURL}getEditedCustomUsers`, {
          headers: {
            accept: 'application/json, text/plain, */*',
          },
          method: 'GET',
          credentials: 'include',
        });
        let data = await response.json();
        setCreators(data);
      } catch (error) {
        console.error(error);
        setCreators([]);
      }
    }

    fetchCreators();
  }, []);

  useEffect(() => {
    if (username.length >= 1) {
      setLoading(true);
      getUsernameSuggestions(username, platform).then((data) => {
        setSuggestions(data);
        setLoading(false);
      });
    }
  }, [username, platform]);

  const handleChange = (event) => {
    setUsername(event.target.value);
    setShowSuggestions(true);
  };

  const handlePlatformClick = (event) => {
    setPlatform(event.target.value);
  };

  return (
    <div className="hello-container">
      <header>
        <img src={infludataLogo} alt="Infludata Logo" className="infludata-logo" />
        <h1 className="header-title">influData Creator Management</h1>
      </header>
      <div className="form-container-2">
        {/* Informational Box */}
        <Card className="info-card mb-4">
          <Card.Header
            className="d-flex justify-content-between align-items-center info-card-header"
            onClick={() => setOpen(!open)}
            aria-controls="info-collapse-text"
            aria-expanded={open}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <strong>Information for Creators and Artist Managers</strong>
            {open ? <FaChevronUp /> : <FaChevronDown />}
          </Card.Header>
          <Collapse in={open}>
            <div id="info-collapse-text">
              <Card.Body>
                <Card.Text>
                  If you are a creator or work for an artist management company, you now have the ability to enhance influData creator profiles by adding custom data and files such as media kits, portfolios, audience insights, and management information. This will help our hundreds of daily users—primarily larger brands and agencies focused on creator marketing—to quickly access information about potential collaborations.
                </Card.Text>
                <Card.Text>
                  By enriching profiles with additional data, we set the profiles as "verified" and elevate them in our search results, increasing visibility. In the near future, we will also introduce management portfolios, allowing interested brands to easily browse all creators within a specific management.
                </Card.Text>
                <Card.Text>
                  Currently, only registered and paying users of influData can view the data you publish.
                </Card.Text>
                <Card.Text>
                  <strong>Getting Started</strong>
                </Card.Text>
                <ul>
                  <li>
                    If you represent a management company, we recommend adding your management first.
                    This allows you to quickly assign it to all creators you manage.
                  </li>
                  <li>If you are a creator, there is no need to create a management profile.</li>
                </ul>
                <Card.Text>
                  If a profile is not listed or you have any questions, please feel free to reach out to us at{' '}
                  <a href="mailto:support@infludata.com">support@infludata.com</a>.
                </Card.Text>
              </Card.Body>
            </div>
          </Collapse>
        </Card>
        </div>

        <div className="form-container">

        {/* Management Selector */}
        <ManagementSelector />

        <Form className="text-center">
          <div className="platform-buttons-wrapper">
            <div className="platform-buttons mb-3">
              <Button
                onClick={handlePlatformClick}
                value="instagram"
                className={`platform-button ${
                  platform === 'instagram' ? 'active instagram' : 'instagram'
                }`}
              >
                Instagram
              </Button>
              <Button
                onClick={handlePlatformClick}
                value="tiktok"
                className={`platform-button ${
                  platform === 'tiktok' ? 'active tiktok' : 'tiktok'
                }`}
              >
                TikTok
              </Button>
              <Button
                onClick={handlePlatformClick}
                value="youtube"
                className={`platform-button ${
                  platform === 'youtube' ? 'active youtube' : 'youtube'
                }`}
              >
                YouTube
              </Button>
            </div>
          </div>

          <h2 className="instructions mb-3">Please type a username you want to edit</h2>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Username"
              aria-label="Username"
              aria-describedby="basic-addon2"
              onChange={handleChange}
              value={username}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
            />
          </InputGroup>
          <Dropdown show={showSuggestions && suggestions.length > 0}>
            <Dropdown.Menu className="suggestions-dropdown">
              {loading ? (
                <Dropdown.Item className="text-center">
                  <Spinner animation="border" variant="primary" />
                </Dropdown.Item>
              ) : (
                suggestions.map((suggestion) => (
                  <Dropdown.Item
                    href={`/profile/${suggestion._id}`}
                    className="text-center"
                    key={suggestion._id}
                  >
                    {suggestion.username}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </div>
      <div className="creator-list-container mt-5">
        <h2 className="creator-list-title mb-4">Your Creators</h2>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Platform</th>
              <th scope="col">Country</th>
              <th scope="col">Added</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {creators.map((creator) => (
              <tr key={creator._id}>
                <td>{creator.username}</td>
                <td>{creator._index}</td>
                <td>{creator.country}</td>
                <td>{new Date(creator.lastEdited).toLocaleDateString()}</td>
                <td>
                  <Button href={`/profile/${creator._id}`} variant="primary" size="sm">
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Hello;
