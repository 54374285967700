import React, { useState, useContext } from 'react';
import { Dropdown, Button, Modal, Form, Spinner, Image, Alert } from 'react-bootstrap';
import { ManagementContext } from './ManagementContext';

const ManagementSelector = () => {
  const {
    managements,
    selectedManagement,
    changeManagement,
    addManagement,
  } = useContext(ManagementContext);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    city: '',
    description: '',
    contactMail: '',
    contactPhone: '',
    website: '',
    logo: null,
  });

  const validateFile = (file) => {
    // Check file size (50MB in bytes)
    const MAX_FILE_SIZE = 50 * 1024 * 1024;
    if (file.size > MAX_FILE_SIZE) {
      return 'File size must be less than 50MB';
    }

    // Check file type
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      return 'Only JPG, PNG, and GIF files are allowed';
    }

    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileError('');

    if (file) {
      const error = validateFile(file);

      if (error) {
        setFileError(error);
        event.target.value = ''; // Reset file input
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          logo: reader.result,
        }));
      };
      reader.onerror = () => {
        setFileError('Error reading file');
        event.target.value = ''; // Reset file input
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectManagement = (management) => {
    changeManagement(management.managementId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await addManagement(formData);
    setShowModal(false);
    setFormData({
      name: '',
      country: '',
      city: '',
      description: '',
      contactMail: '',
      contactPhone: '',
      website: '',
      logo: null,
    });
    setLoading(false);
  };

  return (
    <div className="mb-4 text-center">
      <div className="mb-3">
        <h3 className="mb-2" style={{ fontSize: '1.1rem', fontWeight: '400' }}>
          {selectedManagement ? (
            <div className="d-flex align-items-center justify-content-center">
              <Image
                src={selectedManagement.logoUrl}
                alt={selectedManagement.name}
                roundedCircle
                style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                className="me-2"
              />
              <span>{selectedManagement.name}</span>
              <span className="text-muted ms-2" style={{ fontSize: '0.9rem' }}>
                ({selectedManagement.city}, {selectedManagement.country})
              </span>
            </div>
          ) : (
            'No Management Selected or Independent'
          )}
        </h3>

        <Dropdown className="d-inline-block">
          <Dropdown.Toggle
            variant="primary"
            style={{
              fontSize: '0.9rem',
              padding: '0.375rem 1rem',
            }}
          >
            Select Your Management
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: '250px' }}>
            {managements.map((management) => (
              <Dropdown.Item
                key={management.managementId}
                onClick={() => handleSelectManagement(management)}
              >
                <div className="d-flex align-items-center">
                  <Image
                    src={management.logoUrl}
                    alt={management.name}
                    roundedCircle
                    style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                    className="me-2"
                  />
                  <div className="text-start">
                    <div>{management.name}</div>
                    <small className="text-muted">
                      {management.city}, {management.country}
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <div className="mt-2">
          <button
            onClick={() => setShowModal(true)}
            className="btn btn-link p-0"
            style={{
              fontSize: '0.8rem',
              textDecoration: 'underline',
              color: 'var(--bs-primary)',
              backgroundColor: 'transparent',
            }}
          >
            + Add your management
          </button>
        </div>
      </div>

      {/* Modal for adding new management */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Management</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
          <Form.Group className="mb-3">
              <Form.Label>Management Name (full company name)*</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Country*</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>City*</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Management Description (will be public)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contact Email*</Form.Label>
              <Form.Control
                type="email"
                name="contactMail"
                value={formData.contactMail}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contact Phone</Form.Label>
              <Form.Control
                type="tel"
                name="contactPhone"
                value={formData.contactPhone}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Website (starts with https://)*</Form.Label>
              <Form.Control
                type="url"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Logo*</Form.Label>
                <Form.Control
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={handleFileChange}
                required
                />
                <Form.Text className="text-muted">
                Maximum file size: 50MB. Allowed formats: JPG, PNG, GIF
                </Form.Text>
                {fileError && (
                <Alert variant="danger" className="mt-2">
                    {fileError}
                </Alert>
                )}
          </Form.Group>          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Adding...
                </>
              ) : (
                'Add Management'
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default ManagementSelector;
