import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import './ProfilePage.css';
import countryArray from './util/countryArray';
import languageArray from './util/languageArray.js';
import FileUpload from './util/DragDrop';
import ProfileManagementSelection from './ProfileManagementSelection';
import { SERVERURL } from '../config/VARS';
import SocialHandleInput from './SocialHandleInput'; // Import the new component

import {
  Container,
  Button,
  Table,
  Form,
  Spinner,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import infludataLogo from './infludata-logo.png';
import customFetch from './customFetch';

async function getUserById(id) {
  const response = await customFetch(
    `${SERVERURL}getUserById?objectId=${id}&fromManageInfluData=true`,
    {
      headers: {
        accept: 'application/json, text/plain, */*',
      },
      method: 'GET',
      credentials: 'include',
    }
  );
  const data = await response.json();
  console.log(data.userArray[0]);
  return data.userArray[0];
}

async function saveUserData(userData) {
  const response = await fetch(`${SERVERURL}storeManagementData`, {
    headers: {
      'Content-Type': 'application/json',
      accept: '*/*',
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(userData),
  });
  console.log('Response:', response);
  return response;
}

function ProfilePage({ match }) {
  const [user, setUser] = useState({});
  const [oldUser, setOldUser] = useState({});
  const [filenames, setFilenames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMainManagement, setSelectedMainManagement] = useState(null);

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const data = await getUserById(match.params.id);
      setUser(data);
      setOldUser(data);
      setIsLoading(false);
    }

    const getSelectedMainManagement = async () => {
      try {
        const storedManagement = localStorage.getItem('selectedManagement');
        if (storedManagement) {
          setSelectedMainManagement(JSON.parse(storedManagement));
        }
      } catch (error) {
        console.error('Error getting selected management:', error);
      }
    };

    fetchData();
    getSelectedMainManagement();
  }, [match.params.id]);

  // Handle browser back button and page refresh
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the warning dialog
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [user, oldUser]);

  const hasUnsavedChanges = () => {
    // Deep compare user and oldUser objects
    return JSON.stringify(user) !== JSON.stringify(oldUser);
  };

  const handleBackButtonClick = () => {
    if (hasUnsavedChanges()) {
      if (window.confirm('Attention: You did not save your changes!')) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const userChanges = {};

    for (const key in user) {
      if (
        user[key] !== oldUser[key] ||
        key === 'platform' ||
        key === '_id' ||
        key === '_index' ||
        key === 'username'
      ) {
        userChanges[key] = user[key];
      }
    }

    if (filenames && filenames.length >= 1) {
      userChanges.uploadedFiles = filenames;
    }

    const response = await saveUserData(userChanges);
    console.log('Response:', response);
    if (response.ok) {
      setLoading(false);
      setShowModal(true);
      setOldUser(user); // Update oldUser after successful save
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const availabilityLabels = {
    1: 'No availability',
    3: 'Partly available',
    5: 'Open for collaborations',
  };

  return (
    <Container className="profile-page">
      <Prompt
        when={hasUnsavedChanges()}
        message="Attention: You did not save your changes!"
      />
      <header>
        <img
          src={infludataLogo}
          alt="Infludata Logo"
          className="infludata-logo"
        />
        <h1 className="header-title">influData Creator Management</h1>
      </header>
      {isLoading ? (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <Button className="back-button" onClick={handleBackButtonClick}>
            &larr; Back
          </Button>
          <h2 className="text-center">Profile: {user.username}</h2>
          <img
            className="profile-pic"
            src={user.profilePicURL || infludataLogo}
            alt="Profile"
          />
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Account Type</th>
                <td>
                  <Form.Select
                    value={
                      !user.accountType
                        ? user.typeScore >= 0
                          ? 'creator'
                          : 'brand'
                        : user.accountType
                    }
                    onChange={(e) =>
                      setUser({ ...user, accountType: e.target.value })
                    }
                  >
                    <option value="creator">Creator</option>
                    <option value="brand">Brand</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <th>Followers</th>
                <td>{user.followers}</td>
              </tr>
              <tr>
                <th>Posts</th>
                <td>{user.posts}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{user.publicEmail}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{user.description}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>
                  <Form.Select
                    value={user.country}
                    onChange={(e) =>
                      setUser({ ...user, country: e.target.value })
                    }
                  >
                    {countryArray.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <th>City</th>
                <td>
                  <Form.Control
                    type="text"
                    value={user.city || ''}
                    onChange={(e) =>
                      setUser({ ...user, city: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Language</th>
                <td>
                  <Form.Select
                    value={user.language}
                    onChange={(e) =>
                      setUser({ ...user, language: e.target.value })
                    }
                  >
                    {languageArray.map((language) => (
                      <option key={language} value={language}>
                        {language}
                      </option>
                    ))}
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>
                  <Form.Select
                    value={user.gender || ''}
                    onChange={(e) =>
                      setUser({ ...user, gender: e.target.value })
                    }
                  >
                    <option value="">Select Gender</option>
                    <option value="m">Male</option>
                    <option value="w">Female</option>
                    <option value="o">Other</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <th colSpan="2" style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '10px', backgroundColor: '#ffe4d9' }}>
                  Management Section
                </th>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: 'left', fontSize: '16px', color: 'gray', paddingBottom: '10px' }}>
                  Attention: You don't need to change this section for the same creator on different platforms.
                  If you connect the other platforms via Social Handles, we will update this data across all profiles!
                  <br />
                  Uploaded Files will also be available across all platforms for this creator.
                </td>
              </tr>
              <ProfileManagementSelection 
                user={user}
                setUser={setUser}
                selectedMainManagement={selectedMainManagement}
              />
              <tr>
                <th>WhatsApp Number</th>
                <td>
                  <Form.Control
                    type="text"
                    value={user.collaborationInfo?.whatsApp || ''}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        collaborationInfo: {
                          ...user.collaborationInfo,
                          whatsApp: e.target.value,
                        },
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Contact Mail</th>
                <td>
                  <Form.Control
                    type="email"
                    value={user.collaborationInfo?.contactMail || ''}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        collaborationInfo: {
                          ...user.collaborationInfo,
                          contactMail: e.target.value,
                        },
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Contact Link</th>
                <td>
                  <Form.Control
                    type="url"
                    value={user.collaborationInfo?.contactLink || ''}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        collaborationInfo: {
                          ...user.collaborationInfo,
                          contactLink: e.target.value,
                        },
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Availability for Collaborations</th>
                <td>
                  <Form.Group as={Row} className="mb-3">
                    <Col sm={12}>
                      {Object.entries(availabilityLabels).map(([value, label]) => (
                        <Form.Check
                          type="radio"
                          label={label}
                          name="availability"
                          id={`availability-${value}`}
                          key={value}
                          value={value}
                          checked={user.collaborationInfo?.availability === parseInt(value)}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              collaborationInfo: {
                                ...user.collaborationInfo,
                                availability: parseInt(e.target.value),
                              },
                            })
                          }
                          inline
                        />
                      ))}
                    </Col>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <th>Creator Pricing</th>
                <td>
                  <Form.Group as={Row} className="mb-3">
                    <Col sm={12}>
                      {/* Instructional Text */}
                      <Form.Text className="text-muted">
                      This range provides brands and agencies with an estimated cost for a standard collaboration, such as multiple stories over a week, plus a post on one or more platforms. Use the 'Notes' section to add any specific details or insights about the pricing.
                      </Form.Text>

                      {/* Pricing Options */}
                      <Form.Check
                        type="checkbox"
                        label="Request Pricing (don't publish any pricing about the creator)"
                        name="byRequest"
                        id="byRequest"
                        checked={user.collaborationInfo?.pricing?.byRequest || false}
                        style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px', fontSize: '15px' }}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            collaborationInfo: {
                              ...user.collaborationInfo,
                              pricing: {
                                ...user.collaborationInfo.pricing,
                                byRequest: e.target.checked,
                                fromPrice: e.target.checked ? '' : user.collaborationInfo.pricing?.fromPrice,
                                toPrice: e.target.checked ? '' : user.collaborationInfo.pricing?.toPrice,
                              },
                            },
                          })
                        }
                      />

                      {/* From Price Input */}
                      <Form.Control
                        type="number"
                        placeholder="From €"
                        value={user.collaborationInfo?.pricing?.fromPrice || ''}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            collaborationInfo: {
                              ...user.collaborationInfo,
                              pricing: {
                                ...user.collaborationInfo.pricing,
                                byRequest: false,
                                fromPrice: e.target.value,
                              },
                            },
                          })
                        }
                        disabled={user.collaborationInfo?.pricing?.byRequest}
                      />

                      {/* To Price Input */}
                      <Form.Control
                        type="number"
                        placeholder="To €"
                        value={user.collaborationInfo?.pricing?.toPrice || ''}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            collaborationInfo: {
                              ...user.collaborationInfo,
                              pricing: {
                                ...user.collaborationInfo.pricing,
                                byRequest: false,
                                toPrice: e.target.value,
                              },
                            },
                          })
                        }
                        disabled={user.collaborationInfo?.pricing?.byRequest}
                      />

                      {/* Pricing Notes */}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Pricing Notes (e.g. scope)"
                        value={user.collaborationInfo?.pricing?.pricingNote || ''}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            collaborationInfo: {
                              ...user.collaborationInfo,
                              pricing: {
                                ...user.collaborationInfo.pricing,
                                pricingNote: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                </td>
              </tr>
              <tr>
              <th>Social Handles</th>
              <td>
                {['instagram', 'tiktok', 'youtube'].map((platform) => {
                  const platformName =
                    platform.charAt(0).toUpperCase() + platform.slice(1);
                  if (platform === user._index) {
                    // Disable or hide the input for the current platform
                    return (
                      <div className="mb-3" key={platform}>
                        <strong>{platformName}</strong>
                        <Form.Control
                          type="text"
                          value={user.username}
                          disabled
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div className="mb-3" key={platform}>
                        <strong>{platformName}:</strong>
                        <SocialHandleInput
                          platform={platform}
                          user={user}
                          setUser={setUser}
                        />
                        <h0 small>(use platform link or username for search)</h0>
                      </div>
                    );
                  }
                })}
              </td>
            </tr>
              <tr>
                <th>Message to Agencies & Brands</th>
                <td>
                  <Form.Control
                    as="textarea"
                    value={user.collaborationInfo?.message || ''}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        collaborationInfo: {
                          ...user.collaborationInfo,
                          message: e.target.value,
                        },
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <FileUpload
            user={user}
            setFilenames={setFilenames}
            setUser={setUser}
          />
          <Button
            className="save-button"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Save Changes'
            )}
          </Button>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Changes Saved</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your changes have been successfully saved to influData. Please
              note that it may take up to 10 minutes for the changes to reflect.
              Any further modifications will overwrite the current updates.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Container>
  );
}

export default ProfilePage;
