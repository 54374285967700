import React, { useState } from 'react';
import './dragDrop.css';
import { SERVERURL } from '../../config/VARS'; // Adjust the path as needed
import { Form, Button, Modal, ListGroup } from 'react-bootstrap';

const FileUpload = ({ user, setUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      setFileDisplayName(''); // Reset display name
      setShowModal(true);
    }
  };

  const handleUpload = async () => {
    if (!fileDisplayName.trim()) {
      alert('Please enter a name for the file.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', fileDisplayName);

    try {
      const response = await fetch(`${SERVERURL}uploadFileForManagement`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      const data = await response.json();
      // Assuming data.fileUrl is the URL of the uploaded file

      const existingFiles =
        user.collaborationInfo?.uploadedFiles && user.collaborationInfo?.uploadedFiles.length > 0
          ? user.collaborationInfo.uploadedFiles
          : [];

      const newUploadedFiles = [
        ...existingFiles,
        { name: fileDisplayName, url: data.fileUrl },
      ];

      setUser({
        ...user,
        collaborationInfo: {
          ...user.collaborationInfo,
          uploadedFiles: newUploadedFiles,
        },
      });

      setShowModal(false);
      setSelectedFile(null);
      setFileDisplayName('');
    } catch (err) {
      console.error(err);
      alert('An error occurred while uploading the file.');
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    const newUploadedFiles = user.collaborationInfo.uploadedFiles.filter(
      (file) => file.url !== fileToRemove.url
    );
    setUser({
      ...user,
      collaborationInfo: {
        ...user.collaborationInfo,
        uploadedFiles: newUploadedFiles,
      },
    });
  };

  return (
    <>
      <div className="upload-section">
        <h4>Upload Files</h4>
        <p>
          Upload files here, like media kits and presentations that will be published on influData.
        </p>
        <Form.Group controlId="formFile">
          <Form.Control
            type="file"
            accept="image/*, application/pdf"
            onChange={handleFileChange}
          />
        </Form.Group>
      </div>

      {/* Modal for entering file name */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter File Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="fileDisplayName">
            <Form.Label>File Name</Form.Label>
            <Form.Control
              type="text"
              value={fileDisplayName}
              onChange={(e) => setFileDisplayName(e.target.value)}
              placeholder="Enter a name for the file"
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)} disabled={uploading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpload} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="uploaded-files-section">
        <h4>Uploaded Files</h4>
        {user.collaborationInfo?.uploadedFiles && user.collaborationInfo.uploadedFiles.length > 0 ? (
          <ListGroup>
            {user.collaborationInfo.uploadedFiles.map((file, index) => (
              <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
                <Button variant="outline-danger" size="sm" onClick={() => handleRemoveFile(file)}>
                  Remove
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p>No files uploaded yet.</p>
        )}
      </div>
    </>
  );
};

export default FileUpload;
