import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Image, Button } from 'react-bootstrap';
import { ManagementContext } from './ManagementContext'; // Adjust the path as needed
import './ProfileManagementSelection.css';

const ProfileManagementSelection = ({ user, setUser }) => {
  const {
    managements,
    selectedManagement: contextSelectedManagement,
  } = useContext(ManagementContext);
  const [selectedManagement, setSelectedManagement] = useState(null);

  useEffect(() => {
    // Initialize selected management from user.collaborationInfo.managementId
    if (user.collaborationInfo && user.collaborationInfo.managementId) {
      const initialManagement = managements.find(
        (management) => management.managementId === user.collaborationInfo.managementId
      );
      setSelectedManagement(initialManagement || null);
    } else {
      setSelectedManagement(null);
    }
  }, [user.collaborationInfo, managements]);

  const handleManagementSelect = (management) => {
    setSelectedManagement(management);

    // Update user data with selected management details
    setUser({
      ...user,
      collaborationInfo: {
        ...user.collaborationInfo,
        management: management?.name || '',
        managementId: management?.managementId || '',
        whatsApp: management?.contactPhone || user.collaborationInfo?.whatsApp || '',
        contactMail: management?.contactMail || user.collaborationInfo?.contactMail || '',
        contactLink: management?.website || user.collaborationInfo?.contactLink || '',
        pricing: management?.pricing || user.collaborationInfo?.pricing || '',
      },
    });
  };

  const handleAddStoredManagement = () => {
    // Use the selectedManagement from context
    handleManagementSelect(contextSelectedManagement);
  };

  // Determine if the button should be shown
  const showAddManagementButton =
    contextSelectedManagement &&
    (!selectedManagement ||
      selectedManagement.managementId !== contextSelectedManagement.managementId);

  return (
    <tr>
      <th>Management</th>
      <td>
        <div className="d-flex align-items-center gap-2">
          <Dropdown className="flex-grow-1">
            <Dropdown.Toggle variant="outline-secondary" className="w-100 text-start">
              {selectedManagement ? (
                <div className="d-flex align-items-center">
                  {selectedManagement.logoUrl && (
                    <Image
                      src={selectedManagement.logoUrl}
                      alt={selectedManagement.name}
                      roundedCircle
                      style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                      className="me-2"
                    />
                  )}
                  <span>{selectedManagement.name}</span>
                  <small className="text-muted ms-2">
                    ({selectedManagement.city}, {selectedManagement.country})
                  </small>
                </div>
              ) : (
                'No Management'
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleManagementSelect(null)}
                active={!selectedManagement}
                className="management-dropdown-item"
              >
                No Management
              </Dropdown.Item>
              {managements.map((management) => (
                <Dropdown.Item
                  key={management.managementId}
                  onClick={() => handleManagementSelect(management)}
                  active={selectedManagement?.managementId === management.managementId}
                  className="management-dropdown-item"
                >
                  <div className="d-flex align-items-center">
                    {management.logoUrl && (
                      <Image
                        src={management.logoUrl}
                        alt={management.name}
                        roundedCircle
                        style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                        className="me-2"
                      />
                    )}
                    <div>
                      <div>{management.name}</div>
                      <small className="text-muted">
                        {management.city}, {management.country}
                      </small>
                    </div>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {showAddManagementButton && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleAddStoredManagement}
            >
              + Add {contextSelectedManagement.name}
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ProfileManagementSelection;
