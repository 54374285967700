// App.js
import React from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Login from "./components/Login";
import Hello from "./components/Hello";
import ProfilePage from "./components/ProfilePage";
import { ManagementProvider } from "./components/ManagementContext"; // Adjust the path as needed

const isAuthenticated = () => {
  // Logic to check if the user is authenticated
  const cookies = document.cookie.split(';');
  const sessionCookie = cookies.find(c => c.trim().startsWith('whoami='));

  if (sessionCookie) {
    console.log('Session cookie is set');
    return true;
  } else {
    console.log('Session cookie is not set');
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        // Wrap the component with ManagementProvider here
        <ManagementProvider>
          <Component {...props} />
        </ManagementProvider>
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

function App() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/hello" component={Hello} />
      <PrivateRoute path="/profile/:id" component={ProfilePage} />
    </BrowserRouter>
  );
}

export default App;
