import React, { createContext, useState, useEffect } from 'react';
import { SERVERURL } from '../config/VARS';
import customFetch from './customFetch';

export const ManagementContext = createContext();

export const ManagementProvider = ({ children }) => {
  const [managements, setManagements] = useState([]);
  const [selectedManagement, setSelectedManagement] = useState(null);

  useEffect(() => {
    fetchManagements();
  }, []);

  const fetchManagements = async () => {
    try {
      const response = await customFetch(`${SERVERURL}getManagementData`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setManagements(data.managementList);
      const activeManagement = data.managementList.find(
        (x) => x.managementId === data.activeManagement
      );
      setSelectedManagement(activeManagement);
    } catch (error) {
      console.error('Error fetching managements:', error);
    }
  };

  const changeManagement = async (managementId) => {
    try {
      const response = await customFetch(`${SERVERURL}changeManagementForUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ managementId }),
      });
      const data = await response.text();
      // Update selectedManagement after successful change
      const newSelectedManagement = managements.find(
        (management) => management.managementId === managementId
      );
      console.log('newSelectedManagementnewSelectedManagement', newSelectedManagement)
      setSelectedManagement(newSelectedManagement);
      console.log('Management changed successfully:', data);
    } catch (error) {
      console.error('Error changing management:', error);
    }
  };

  const addManagement = async (formData) => {
    try {
      const response = await customFetch(`${SERVERURL}addManagement`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log('THE RES', response)
      if (response.ok) {
        // Assuming the server returns the newly added management
        const newManagement = await response.json();
        await fetchManagements();
        // Select the newly added management
        changeManagement(newManagement.managementId);
      }
    } catch (error) {
      console.error('Error adding management:', error);
    }
  };

  return (
    <ManagementContext.Provider
      value={{
        managements,
        selectedManagement,
        setSelectedManagement,
        changeManagement,
        addManagement,
      }}
    >
      {children}
    </ManagementContext.Provider>
  );
};
