// SocialHandleInput.js
import React, { useState, useEffect } from 'react';
import { Form, Image } from 'react-bootstrap';
import customFetch from './customFetch';
import { SERVERURL } from '../config/VARS';
import './SocialHandleInput.css'; // Create a CSS file for styling

const SocialHandleInput = ({ platform, user, setUser }) => {
  const [inputValue, setInputValue] = useState(
    user.socialHandles?.[`${platform}Username`] || ''
  );
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const getUsernameSuggestions = async (letters, platform) => {
    if (letters.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await customFetch(
        `${SERVERURL}getUserSuggestionByLetters?letters=${encodeURIComponent(
          letters
        )}&index=${platform}`,
        {
          headers: {
            accept: 'application/json, text/plain, */*',
          },
          method: 'GET',
          credentials: 'include',
        }
      );
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowSuggestions(true);

    // Reset the selected suggestion
    setUser({
      ...user,
      socialHandles: {
        ...user.socialHandles,
        [platform]: null,
        [`${platform}Username`]: value,
      },
    });

    getUsernameSuggestions(value, platform);
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputValue(suggestion.username);
    setShowSuggestions(false);

    // Update user object with selected suggestion
    setUser({
      ...user,
      socialHandles: {
        ...user.socialHandles,
        [platform]: suggestion._id,
        [`${platform}Username`]: suggestion.username,
      },
    });
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setShowSuggestions(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="position-relative social-handle-input">
      <Form.Control
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {showSuggestions && suggestions.length > 0 && (
        <div className="suggestions-dropdown">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion._id}
              className="suggestion-item"
              onClick={() => handleSuggestionSelect(suggestion)}
            >
              {suggestion.profilePicURL && (
                <Image
                  src={suggestion.profilePicURL}
                  alt={suggestion.username}
                  roundedCircle
                  style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                  className="me-2"
                />
              )}
              <span>{suggestion.username}</span>
              <small className="text-muted ms-2">
                {suggestion.displayName}
              </small>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SocialHandleInput;
